import { TBannerTypes } from '../../../types/newbuildingBanners/banners';

export type TBannerTheme = 'brand-pink' | 'primary-basic' | 'brand-peach' | 'gradient-linear-primary-brand-pink';

const typeToThemeDefaultSet: Record<TBannerTypes, TBannerTheme> = {
  promosFlight: 'primary-basic',
  allPromos: 'primary-basic',
  kpn: 'brand-pink',
  topPromos: 'brand-peach',
  lastChanceDiscount: 'brand-pink',
};

export const bannerTypeToDefaultTheme = ({ type }: { type: TBannerTypes }) => {
  return typeToThemeDefaultSet[type];
};
