import * as React from 'react';
import { IRecommendations } from '../../types/newbuildingRecommendations';
import { ItemsCarousel, RecommendationCard } from '../../components/NewbuildingRecommendations';
import { RecommendationsSection } from '../../components/NewbuildingRecommendations/RecommendationsSection/RecommendationsSection';
import { RecommendationReview } from '../../components/NewbuildingRecommendations/RecommendationReview';
import { Underground } from '../../components/NewbuildingRecommendations/Underground';
import { Link } from '@cian/ui-kit';

import { bannerTypeToDefaultTheme } from '../../components/NewbuildingBanners/utils/bannerTypeToTheme';
import { Banner } from './Banner';
import { TBanners } from '../../types/newbuildingBanners/banners';
import { mergeBannersAndRecommendations } from './helpers/mergeBannersAndRecommendations';
import { useNewbuildingRecommendations } from './useNewbuildingRecommendations';
import { ELoadingStatus } from '../../types/loadingStatus';
import { isNewbuildingBanner } from './helpers/isNewbuildingBanner';
import { AllNewbuildingsBanner } from '../../components/NewbuildingBanners/AllNewbuildinsBanner/AllNewbuildingsBanner';
import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import { onAllOffersBannerClickAnalytic, onAllOffersBannerShowAnalytic } from './analytics/analytic';
import * as styles from '../../components/NewbuildingRecommendations/ItemsCarousel/ItemsCarousel.css';

export const NewbuildingRecommendations = () => {
  const {
    newbuildings,
    banners,
    onRecommendationsShow,
    onShowAllClick,
    onBannerShow,
    recommendationsStatus,
    onBannerClick,
    onCardClick,
  } = useNewbuildingRecommendations();

  const bannersAndRecommendations = mergeBannersAndRecommendations({ newbuildings, banners });

  const renderOutside = React.useCallback(() => {
    const items = bannersAndRecommendations.map((item: IRecommendations | TBanners, idx) => {
      if (idx === bannersAndRecommendations.length - 1) {
        return (
          <>
            {isNewbuildingBanner(item) && (
              <li key={`banner-${idx}`}>
                <ActionAfterViewed
                  parentBlockCssClass={idx > 4 ? styles['newbuilding-recommendations-banners-container'] : undefined}
                  callback={onBannerShow({ type: item.type })}
                >
                  <Banner
                    onClick={onBannerClick({ type: item.type })}
                    data={item}
                    themeMapper={bannerTypeToDefaultTheme}
                  />
                </ActionAfterViewed>
              </li>
            )}
            <li key={`banner-${idx}`}>
              <ActionAfterViewed
                parentBlockCssClass={idx > 4 ? styles['newbuilding-recommendations-banners-container'] : undefined}
                callback={onAllOffersBannerShowAnalytic}
              >
                <AllNewbuildingsBanner
                  onClick={onAllOffersBannerClickAnalytic}
                  url={newbuildings.allRecommendations.link}
                />
              </ActionAfterViewed>
            </li>
          </>
        );
      }

      if (isNewbuildingBanner(item)) {
        return (
          <li key={`banner-${idx}`}>
            <ActionAfterViewed
              parentBlockCssClass={idx > 4 ? styles['newbuilding-recommendations-banners-container'] : undefined}
              callback={onBannerShow({ type: item.type })}
            >
              <Banner onClick={onBannerClick({ type: item.type })} data={item} themeMapper={bannerTypeToDefaultTheme} />
            </ActionAfterViewed>
          </li>
        );
      } else {
        return (
          <li key={`recommendation-${idx}`}>
            <RecommendationCard
              url={item.url}
              name={`ЖК ${item.name}`}
              status={item.status}
              minPrice={item.minPrice || ''}
              mediaUrl={item.media[0] ? item.media[0].url : ''}
              underground={item.underground ? <Underground underground={item.underground} /> : null}
              address={item.underground ? null : item.address}
              reviews={item.review.reviewCount ? <RecommendationReview review={item.review} /> : null}
              key={item.id}
              onClick={onCardClick({ newbuildingId: item.id, position: idx })}
            />
          </li>
        );
      }
    });

    return items;
  }, [bannersAndRecommendations]);

  if (recommendationsStatus !== ELoadingStatus.Success || !bannersAndRecommendations.length) {
    return null;
  }

  return (
    <ActionAfterViewed callback={onRecommendationsShow}>
      <RecommendationsSection
        title="Подобрали ЖК для вас"
        watchAll={
          <Link href={newbuildings?.allRecommendations?.link} onClick={onShowAllClick} target="_blank">
            Посмотреть еще
          </Link>
        }
      >
        <ItemsCarousel scrollVisible renderOutside={renderOutside} />
      </RecommendationsSection>
    </ActionAfterViewed>
  );
};
